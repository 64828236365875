import React from 'react';
import PropTypes from 'prop-types';
import { Menu, Popover } from 'antd';

import { enquireScreen } from 'enquire-js';
import logoBg from '../assets/imgs/logo.png';


class Header extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    isMobile: PropTypes.bool,
    activefaq: PropTypes.bool,
    activeProduct: PropTypes.bool,
    activeHome: PropTypes.bool,
    handleclick: PropTypes.func,
  }

  state = {
    menuVisible: false,
    menuMode: 'horizontal',
  };

  componentDidMount() {
    enquireScreen((b) => {
      this.setState({ menuMode: b ? 'inline' : 'horizontal' });
    });
  }

  handleShowMenu = () => {
    this.setState({ menuVisible: !this.state.menuVisible});
  }


  handleMenuClick = (event) => {
    this.props.handleclick(event.key);
    this.setState({ menuVisible: false});
  }

  render() {
    const { activefaq, activeProduct, activeHome, handleclick, isMobile } = this.props;
    const { menuMode, menuVisible } = this.state;

    const menu = (
      <Menu mode="inline" id="nav" key="nav" onClick={(e) => this.handleMenuClick(e)}>
        <Menu.Item key="home" >
          <span>Home</span>
        </Menu.Item>
        <Menu.Item key="product">
          <span>Product</span>
        </Menu.Item>
        <Menu.Item key="FAQ">
          <span>FAQ</span>
        </Menu.Item>
      </Menu>
    );
    console.log(isMobile,'isMobile');
    return (
    <header {...this.props} >
      <div className={ `${isMobile ? 'headerWrapper-mobile':'headerWrapper'} ${this.props.className}`}>
        <img src={logoBg} className="logo" alt='logo'/>
        {
          menuMode === 'horizontal' ? (
          <div className='headerBtn'>
            <div className={`button  ${activeHome && 'active-btn'}`} onClick={() => handleclick('home')}>
              Home
            </div>
            <div className={`button  ${activeProduct && 'active-btn'}`} onClick={() => handleclick('product')}>
              Product
            </div>
            <div className={`button  ${activefaq && 'active-btn'}`} onClick={() => handleclick('FAQ')}>
                FAQ
            </div>
           
            
          </div>) : (
          <Popover
            overlayClassName="popover-menu"
            placement="bottomRight"
            content={menu}
            trigger="click"
            visible={menuVisible}
            arrowPointAtCenter
            onVisibleChange={this.handleShowMenu}
          >
            <svg className="nav-phone-menu" onClick={this.handleShowMenu}>
              <use xlinkHref="#iconmenu1" />
            </svg>
          </Popover>)
        }
      </div>
    </header>
    );
  }
}

export default Header;
