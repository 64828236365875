import React from 'react';
import DocumentTitle from 'react-document-title';
import { enquireScreen } from 'enquire-js';

import './CommonPage.less';


let isMobile = false;
enquireScreen((b) => {
  isMobile = b;
});


class PesoOnlineLoanAgreement extends React.PureComponent {
  state = {
    isMobile,
    // isScroll: false,
  };

  componentDidMount() {
    enquireScreen((b) => {
      this.setState({
        isMobile: !!b,
      });
    });
    // window.addEventListener('scroll', this.bindScroll);
    document.documentElement.scrollTop = document.body.scrollTop = 0;
  }



  render() {
    return (
    
    <div className={'contentWrapper'}>
    <DocumentTitle title="Terminos de Uso" />
   <h1>Terminos de Uso</h1>
   <p>This Agreement constitutes the relationship between the User and the Lender (referred to as "PesoOnline") and the use of related functions and allied services (referred to as "this Agreement"). After the User accedes to this Agreement, the User is deemed to have agreed and expressly consented to the terms and conditions of this Agreement and is theretofore bound by the terms, conditions and obligations as stated therein. The contents of this Agreement shall include all the terms and conditions of PesoOnline (including but not limited to the relevant registration agreements, notices and privacy policies, etc.). All terms and conditions constitute an integral part of this Agreement and shall be binding between the parties.</p><p>
</p><p>
Credit/Loan Details</p><p>
</p><p>
Debtor Name:</p><p>
</p><p>
ID Card Number:</p><p>
</p><p>
Contact Number:</p><p>
</p><p>
Loan Purpose:</p><p>
</p><p>
Loan Amount:</p><p>
</p><p>
Amount Transferred:</p><p>
</p><p>
Remaining amount:</p><p>
</p><p>
Agreed Repayment Date:</p><p>
</p><p>
Collection of other fees:</p><p>
</p><p>
Payment System Service Fee:</p><p>
</p><p>
Loan Platform Usage Fee:</p><p>
</p><p>
Risk Valuation Service Fee:</p><p>
</p><p>
Analysis Service Fee:</p><p>
</p><p>
Note:</p><p>
</p><p>
1. PesoOnline is a company that is engaged in the business of providing personal mobile phone mortgage services and loan management services.</p><p>
</p><p>
2. Users apply for personal mobile phone mortgage through PesoOnline by providing basic information in order for PesoOnline to verify, validate, and cross reference such information for the loan’s approval.</p><p>
</p><p>
3. PesoOnline provides risk rating services wherein it is akin to the credit rating services being used by the Credit Management Association of the Philippines (CMAP) in granting future and additional loans.</p><p>
</p><p>
4. PesoOnline is a local service tailor made to the needs of Filipinos. Use of PesoOnline’s services from outside the Philippines shall immediately be denied.</p><p>
</p><p>
User must abide by the following undertakings:</p><p>
</p><p>
I. User undertakes that:</p><p>
</p><p>
a) They have a regular paying job and have the financial capacity to pay the loan being applied for.</p><p>
</p><p>
b) They will abide by the terms and conditions of the Agreement.</p><p>
</p><p>
c) They will not do following:</p><p>
</p><p>
1.Provide false, untrue, fraudulent, and invalid information in order to secure a loan from PesoOnline;</p><p>
</p><p>
2.Provide misleading information to PesoOnline;</p><p>
</p><p>
3.Connive, conspire, collude or otherwise scheme with PesoOnline directors, partners and employees in securing or extending a loan;</p><p>
</p><p>
4.Attempt to commit or commit fraud prior and during the life of this agreement.</p><p>
</p><p>
Violations of the aforementioned undertakings shall cause the cancellation of this Agreement and the User shall be held liable for the full amount of the loan with interest and/or damages as the case may be.</p><p>
</p><p>
II. Enrollment and Usage</p><p>
</p><p>
a) The user must download the PesoOnline application to their mobile phone or device. The user shall then provide the required information for purposes of registration. After successful registration, the user will be given his/her account details. The user may then proceed with the loan application as intended.</p><p>
</p><p>
b) PesoOnline is a platform that requires a steady and stable internet connection. For purposes of registration and loan application, the user must be connected to a secure internet connection via WIFI or LTE as the case maybe. Internet connection is necessary in order to use the services of PesoOnline. Location access is also needed in order for PesoOnline to verify and validate if the user is indeed located in the Philippines.</p><p>
</p><p>
c) The User shall fill out the fields as required. The information provided must be valid and true as this will be subject to validation and verification by PesoOnline. These pieces of information shall be processed, collected, used and retained by PesoOnline subject to the limitations as mandated by the pertinent Laws and Implementing Rules and Regulations in relation to Data Privacy Laws of the Philippines.</p><p>
</p><p>
d) The User must follow the instructions in filling out the mobile phone number, password, and the verification code in order to properly use the PesoOnline application.</p><p>
</p><p>
e) Subject to the provisions of the Privacy Policy, the User agrees, consents and authorizes PesoOnline to collect, process and retain personal information of the User such as, but not limited to: name, address, phone number, mobile phone number, financial information, credit status information, phone contacts and other related information in order to achieve the purpose of this Agreement.</p><p>
</p><p>
f) The User undertakes that all personal information provided to PesoOnline are true and valid. PesoOnline is permitted to verify and/or validate such information through credit services verification, bank information verification, background investigation, company information verification, and other sources of information necessary to achieve the purpose of this Agreement.</p><p>
</p><p>
g) PesoOnline ensures that personal information of the User shall be protected and secured from unauthorized access, breach, disclosure or sharing. The User agrees, consents and authorizes PesoOnline to use, manage, disclose personal data, information, archives, data sources to Third Parties in order to achieve the purpose of this Agreement including but not limited to collection, data verification, use telecom operators, among others. Subject to the limitations as set forth under the Data Privacy Act and its Implementing Rules and Regulations.</p><p>
</p><p>
h) User agrees, consents and authorizes PesoOnline to use User's name to sign "Loan Approval" or "Loan Contract", which will be in electronic form. PesoOnline will transmit this data to allied Banking Institutions for purposes of securing the said loan.</p><p>
</p><p>
i) The User expressly agrees that if prior to or during the life of the "Loan Contract", the said User breaches its terms and conditions, PesoOnline is authorized to do the following:</p><p>
</p><p>
1.If the loan is under process, it will be denied outright;</p><p>
</p><p>
2.If the loan has already been approved, it will be cancelled and the User shall be liable for the entire loan amount along with interest and/or damage as the case may be;</p><p>
</p><p>
3.If after the loan is cancelled the User fails to settle the amount due as determined, PesoOnline shall consider the same as a delinquent account and refer the same to the proper department for collection;</p><p>
</p><p>
4.If the collection attempts fail due to inability to contact the User and his reference contact persons among others, PesoOnline shall attempt to contact individuals from the phone contacts collected from the User subject to the limitations set forth under pertinent laws. No loan information shall be disclosed to Third Party contacts except for the User’s name and PesoOnline’s contact information.</p><p>
</p><p>
j) User is allowed to terminate this Agreement provided he pays the total amount due plus interest as determined and agreed upon by the Parties.</p><p>
</p><p>
k) The User agrees, consents, and authorizes that PesoOnline may deduct the corresponding charges, fees, interest and other related fees from the time the User’s loan has been approved.</p><p>
</p><p>
l) The User agrees that if the User chooses to pre-terminate the PesoOnline transaction, the User will be held liable to pay the full amount of the loan, subject to interests and pre-termination fees which may be deducted from the Bank account directly.</p><p>
</p><p>
m) The User agrees, consents, and authorizes PesoOnline to use User information to make business recommendations. PesoOnline will send out business and commercial ads to mobile phones, emails, and SMS among other.</p><p>
</p><p>
III. Obligations of the Parties</p><p>
</p><p>
a) After the User signs the contract, the User is obligated to pay back what is owed in accordance with Agreement. Such as the due date and the amount due stated in the loan details.</p><p>
</p><p>
b) Without the express written consent of PesoOnline, the User shall not be entitled to transfer the rights to any third party under the Agreement, otherwise the transfer shall be declared null and void.</p><p>
</p><p>
c) PesoOnline reserves the right to cancel the User's loan, if the User violates breaches the provisions of the Agreement.</p><p>
</p><p>
d) This Agreement may only be modified, amended or terminated when both parties agree to such proposed amendment. PesoOnline reserves the right to change, by written notice to the User, the provisions of this Agreement in any way it may deem necessary or advisable to carry out the purpose of this Agreement as a result of any change in applicable laws or regulations or any future law, regulation, ruling, or judicial decision.</p><p>
</p><p>
e) During the life of the Agreement, PesoOnline shall provide the User with the services agreed upon.</p><p>
</p><p>
f) User is required to pay PesoOnline for the service fee and other related fees in accordance with the terms and conditions of this Agreement.</p><p>
</p><p>
g) PesoOnline has the right to use personal data information of the User, to perform data checking and risk assessment. PesoOnline may recommend loan plans tailor fit with the User’s capacity to pay.</p><p>
</p><p>
h) PesoOnline has the right to verify and validate the information and data provided by the User. The User undertakes to provide correct information.</p><p>
</p><p>
i) To ensure the security of transactions, PesoOnline is entitled to ascertain whether the User is using the application for legal means. PesoOnline reserves the right to have the User explain any findings, reports or information of illegal usage in writing before the same is acted upon.</p><p>
</p><p>
j) If PesoOnline discovers that the User provided false and invalid information or is attempting to deceive or has deceived PesoOnline through fraudulent acts or machinations, PesoOnline shall have the right to terminate or cancel the transaction with the User without due notice.</p><p>
</p><p>
k) If user proceed with borrowing money, it means that user agree to waive his/her rights to the loan-related 60 days grace period stipulated in the Bayanihan to Heal as One Act (Bayanihan 2), and shall repay the loan according to the date agreed in this agreement.</p><p>
</p><p>
IV. Delinquent Payments</p><p>
</p><p>
a) When the User is late in settling what is owed and the User cannot be contacted, PesoOnline will contact the emergency contact which was provided and a collection attempt may be made on the said emergency contact.</p><p>
</p><p>
b) If the emergency contacts provided cannot be contacted as well or is otherwise a false or invalid information, the User authorizes and consents that PesoOnline will use the phone contacts collected in order to locate the User. PesoOnline will not disclose nor divulge any information regarding the loan or this Agreement. However, PesoOnline will notify the Third-Party contact that the User cannot be contacted and that PesoOnline will need the User’s new contact information.</p><p>
</p><p>
c) If the User is late in payment, PesoOnline shall have the right to collect the payment by issuing notices in the User's account, seek assistance from a collection company or pursue legal recourse by filing the corresponding Civil and Criminal action necessary against the erring User.</p><p>
</p><p>
d) PesoOnline shall have the right to transfer, assign, or cede all or part of the claims to third parties including but not limited to such as the use of collection companies and others. The User agrees, consents, and authorizes PesoOnline to disclose necessary information in order that the collection companies may properly serve its purpose subject to the restrictions of the Data Privacy Act.</p><p>
</p><p>
e) Collection attempts will only cease once the full amount has been settled or when the User has made an arrangement to settle the minimum amount due to be paid on a specific date. Failure to settle the payment arrangement shall cause PesoOnline to continue its collection attempt as provided under this Agreement.</p><p>
</p><p>
f) During the loan period, the user has the right to use the collateral. Before the user pays off the loan principal and interest, PesoOnline has the ownership of the collateral.</p><p>
</p><p>
V. Breach of Contract</p><p>
</p><p>
Any violation of any provision hereof, any incomplete or mistaken performance of any obligation provided hereunder, any misrepresentation made hereunder, any material nondisclosure or omission of any material fact, or any failure to perform any covenants provided hereunder by any Party shall constitute a breach of this Agreement. The breaching Party shall be liable for any such breach pursuant to the applicable laws.</p><p>
</p><p>
VI. Force Majeure</p><p>
</p><p>
In the event the Parties is prevented from complying with their obligations due to war or national emergency, civil commutation, strike and labor disputes, acts of GOD, force majeure, or other causes beyond its control, this Agreement shall be deemed suspended for the duration thereof; provided, however, that upon cessation thereof, this Agreement shall be deemed automatically in force again unless its period of effectively has, in the meantime, expired, in which event, the renewal of this Agreement shall be subject to negotiation between the parties hereto.</p><p>
</p><p>
VII. Applicable Law and Dispute Resolution</p><p>
</p><p>
In case of suit arising out of or in connection with the interpretation or enforcement of any provision of this Agreement, exclusive venue therefore shall be the proper courts of the City of Makati.</p><p>
</p><p>
VIII. Validity of Contracts, Termination and Separability</p><p>
</p><p>
a) This Agreement has been reviewed by the Parties. The Parties have had a full opportunity to negotiate the terms and conditions of this Agreement. Accordingly, the Parties expressly waive any common-law or statutory rule of construction that ambiguities should be construed against the drafter of this Agreement, and agree, covenant, and represent that the language in all parts of this Agreement shall be in all cases construed as a whole, according to its fair meaning and in order to pursue its lawful purpose.</p><p>
</p><p>
b) In case any provision in this Agreement shall be declared invalid, illegal or unenforceable by a court of competent jurisdiction, the validity, legality and enforceability of the remaining provisions hereof and thereof shall not in any way be affected or impaired thereby.</p><p>
</p><p>
c) If the User has clicked "Completed" in the PesoOnline platform, the User will be deemed to have agreed to the terms and conditions of this Agreement and thus the Agreement shall be binding between the User and PesoOnline.</p><p>
</p><p>
d) Once the User has successfully paid off his loan with PesoOnline, this agreement shall automatically be terminated unless the User opts to start a new loan. In such case, the terms and conditions of this agreement shall be binding upon the User subject to future amendments and the consent of the User.</p><p>
</p><p>
e) Except as otherwise provided in this Agreement, if any provision contained in this Agreement conflicts with any provision in any other documents, the provision contained in this Agreement shall take precedence provided that, the subsequent document is considered as an Amendment to this Agreement.</p><p>
</p><p>
IX. Rights of the Data Owner</p><p>
</p><p>
Subject to the pertinent provisions of the Data Privacy Act, the lender or data owner have the corresponding rights, to wit:</p><p>
</p><p>
a) right to be informed;</p><p>
</p><p>
b) right to object;</p><p>
</p><p>
c) right to access;</p><p>
</p><p>
d) right to rectification;</p><p>
</p><p>
e) right to erasure or blocking; and</p><p>
</p><p>
f) right to damages.</p><p>
</p><p>
(No text below, just the signing page of the "Lending Service Agreement")</p><p>
</p><p>
User (Signature): ___________</p><p>
</p><p>
Date: _____/_____/_____</p><p>
</p><p>
PesoOnline (Stamp & Logo)</p><p>
</p><p>
Date: _____/_____/_____</p><p>
</p><p>
SECURITY AGREEMENT</p><p>
</p><p>
This SECURITY AGREEMENT (the "AGREEMENT") made and executed on this ___ day of ___________ 20 ___ at _________________, by and between:</p><p>
</p><p>
(Name of GRANTOR: ), (of legal age: , single/married to ________ _, address:___ ____ __) （________________), hereinafter referred to as "GRANTOR"</p><p>
</p><p>
-and-</p><p>
</p><p>
CODEBLOCK LENDING INC., hereinafter referred to as "SECURED CREDITOR."</p><p>
</p><p>
WITNESSETH:</p><p>
</p><p>
1. The GRANTOR agrees to create a security interest over the movable property described below, in favor of the SECURED CREDITOR, to secure its obligation to pay (amount: ) under the Credit Agreement above.·</p><p>
</p><p>
2. The security interest covered under this Agreement shall cover the movable property described below:</p><p>
</p><p>
(Phone model: Android Device ID: )</p><p>
</p><p>
IN WITNESS WHEREOF, the parties hereunto set their hands on this ____ day of ___________ at _____________________________.</p><p>
</p><p>
[Name of the GRANTOR] [Name of the SECURED CREDITOR]</p><p>
</p><p>
_____________________ _____________________</p><p>
</p><p>
Signed in the Presence of:</p><p>
</p><p>
_____________________ _____________________</p><p>
</p><p>
Witness Witness</p><p>
</p><p>
ACKNOWLEDGEMENT</p><p>
</p><p>
(REPUBLIC OF THE PHILIPPINES)</p><p>
</p><p>
BEFORE ME, a Notary Public for and in ____________ this ____ day of ___ 20__ personally appeared:</p><p>
</p><p>
Name	Competent Evidence of Identity	Date of Issue	Place of Issue</p><p>
GRANTOR			</p><p>
SECURED CREDITOR			</p><p>
known to me as the same persons who executed the foregoing Agreement and acknowledged to me that the same is their free and voluntary act and deed or that of the principals represented by them.</p><p>
</p><p>
I further acknowledge that this instrument, including the foregoing Agreement and this page on which this acknowledge is written, consists of two (2) pages, all signed by the parties and sealed with my notarial seal.</p><p>
</p><p>
WITNESS MY HAND AND SEAL on the date and the place first above written.</p><p>
</p><p>
Doc. No. ___________</p><p>
</p><p>
Page No. ___________</p><p>
</p><p>
Book No. ___________</p><p>
</p><p>
Series of ____________</p>

     </div>

    )
    
  }
}
export default PesoOnlineLoanAgreement;
